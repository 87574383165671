<template>
	<div id="frontFeaturedEvents">
		<featured-events />
	</div>
</template>

<script>
import FeaturedEvents from './components/FeaturedEvents';

export default {
	name: 'App',
	components: {
		FeaturedEvents
	}
};
</script>

<style lang="scss">
#frontFeaturedEvents,
#frontFeaturedEvents * {
	box-sizing: border-box;
}

#frontFeaturedEvents {
	font-family: 'montserrat', sans-serif;
}
</style>
