<template lang="html">
	<div class="featured_events">
		<div v-if="!loaded" class="grid">
			<div class="grid_1-3">
				<div class="loader_wrap">
					<span class="loader loader--large"></span>
				</div>
			</div>

			<div class="grid_1-3">
				<div class="loader_wrap">
					<span class="loader loader--large"></span>
				</div>
			</div>

			<div class="grid_1-3">
				<div class="loader_wrap">
					<span class="loader loader--large"></span>
				</div>
			</div>
		</div>
		<transition name="fade">
			<div v-if="loaded">
				<div class="grid">
					<div class="grid_1-3" v-for="post in posts" :key="post.identifier">
						<div class="featured_event">
							<a :href="eventUrl(post)" class="full_link"></a>
							<div class="featured_event__details">
								<h4 class="featured_event__title">{{ post.name }}</h4>
								<p class="featured_event__date">
									{{ moment(post.datetime_start).format('DD MMMM') }}
								</p>
								<p class="featured_event__view">
									View event
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

Vue.prototype.moment = moment;

export default {
	name: 'featured-events',
	data() {
		return {
			posts: [{ identifier: 1 }, { identifier: 2 }, { identifier: 3 }],
			loaded: false
		};
	},
	created: function() {
		// Alias the component instance as `vm`, so that we
		// can access it inside the promise function
		var vm = this;
		// Fetch our array of posts from an API
		fetch('https://nlfchurch.churchsuite.co.uk/embed/calendar/json?featured=1')
			.then(function(response) {
				return response.json();
			})
			.then(function(data) {
				vm.posts = data.slice(0, 3);
				vm.loaded = true;
			});
	},
	methods: {
		eventUrl(post) {
			const title = encodeURIComponent(post.name)
				.replace(/[!'()*]/g, '')
				.toLowerCase();
			const startDate = moment(post.datetime_start).format('YYYY-MM-DD');
			const endDate = moment(post.datetime_end).format('YYYY-MM-DD');
			const identifier = post.identifier;
			const url =
				'/nlfchurch/event/?title=' +
				title +
				'&date_start=' +
				startDate +
				'&date_end=' +
				endDate +
				'&identifier=' +
				identifier +
				'';
			return url;
		}
	}
};
</script>

<style lang="scss" scoped>
.featured_events {
	position: relative;
	// background-color: $blue;
	// padding: 20px 0;
}

.grid_1-3 {
	max-width: 260px;
	width: 100%;
	min-height: 188px;
	position: relative;
	margin: 0 0 16px;
}

.featured_event {
	height: 188px;
	display: flex;
	border: 5px solid $teal;
	position: relative;
	padding: 30px;
	transition: 0.2s;

	&:hover {
		border-color: #ffffff;

		.featured_event__date {
			opacity: 0;
		}

		.featured_event__view {
			opacity: 0.5;
		}
	}
}

.featured_event__details {
	display: flex;
	flex-direction: column;
}

.featured_event__title {
	min-height: 78px;
	color: #ffffff;
	font-size: 20px;
	font-weight: $bold;
	margin: 0 0 10px;
	line-height: 1.3;
	position: relative;
	flex-grow: 1;
}

.featured_event__date {
	color: $teal;
	font-size: 19px;
	margin: 0;
	transition: 0.2s;
}

.featured_event__view {
	color: #ffffff;
	opacity: 0;
	transition: 0.2s;
	position: absolute;
	bottom: 24px;
	left: 30px;
	font-size: 19px;
	margin: 0;
}

.loader_wrap {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	transform: translateY(-50%);
}

.loader--large {
	width: 60px;
	height: 60px;
	border: 5px solid $teal;
	border-right-color: transparent;
	border-top-color: transparent;
	margin: 0 auto;
}

@media screen and (max-width: 1200px) {
	.featured_event__view {
		left: 0;
		right: 0;
		text-align: center;
	}
}
</style>
